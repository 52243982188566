<template>
  <div>
    <loader :isLoading="data_loaded"></loader>
  </div>
  <div>
    <h2 class="mt-3">ORDERS</h2>
    <div
      class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mb-4 pt-1 p-4 mt-4"
    >
      <div class="mt-1">
        <div class="col-md-"></div>
        <div class="form-group col-md-4 mb--2">
          <div class="input-group input-group-merge input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><em class="ni ni-align-center"></em
              ></span>
            </div>
            <select
              class="form-control"
              placeholder="Fielter"
              type="text"
              v-model="order_status"
              @change="fetch_entity_orders_by_status"
            >
              <option value="All" selected>All</option>
              <option value="orderSubmitted">orderSubmitted</option>
              <option value="orderAccepted">orderAccepted</option>
              <option value="inProgress">inProgress</option>
              <option value="shipping">shipping</option>
              <option value="delivered">delivered</option>
              <option value="orderCanceled">orderCanceled</option>
            </select>
          </div>
        </div>
        <data-table
          v-if="data_loaded"
          id="entityOrders"
          :options="dtb_options.extra"
          :actions="dtb_options.actions"
          :data="dtb_options.data"
          :columns="dtb_options.columns"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/Datatable";
import Loader from "../../components/Loader";
import axios from "axios";
import { alert } from "../../utils/alertUtils";
export default {
  name: "Inquiries",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      data_loaded: false,
      order_status: "All",
      user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: this.$guards.has_perm("order.view_order")
          ? [
              {
                btnText: "Detail",
                btnClass: "btn btn-default btn-sm",
                iconClass: "fa fa-info-circle",
                btnFn: (uuid) => {
                  this.$router.push({
                    name: "OrderDetail",
                    params: { uuid: uuid },
                  });
                },
                btnParamName: "uuid",
              },
            ]
          : [],
        columns: [
          { title: "Order Number", data: "OrderNumber" },
          { title: "Placed On", data: "doneAt",
          render: (data) => {
              return this.$date_format.date_only(data["doneAt"]);
            },
          },
          { title: "order Status", data: "orderStatus" },
          { title: "payment Status", data: "paymentStatus" },
        ],
        extra_options: {},
      },
    };
  },
  watch: {
    order_status() {
      if (this.order_status == "All") {
        this.fetch_entity_orders();
      } else {
        this.fetch_entity_orders_by_status(this.order_status);
      }
    },
  },

  methods: {
    fetch_entity_orders: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.user_profile.entity.uuid}/entity_orders/`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch(() => {
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },

    fetch_entity_orders_by_status: function () {
      if (this.order_status === "All") {
        this.data_loaded = false
        this.fetch_entity_orders();
      } else {
        this.data_loaded = false;
        var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.user_profile.entity.uuid}/entity_orders/by_status/?search=${this.order_status}`;
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.dtb_options.data = res.data;
            this.data_loaded = true;
          })
          .catch(() => {
            alert.error(
              "An error occured please try again,if the error persist kindly contact the owner of this site!"
            );
          });
      }
    },
  },
  created() {
    // this.fetch_entity_orders();
    this.fetch_entity_orders_by_status();
  },
};
</script>